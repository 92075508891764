//サイズ
$spSize:640px;
$pcSize:641px;

//break point
@mixin mq-sp {
  @media screen and (max-width: $spSize) {
    @content;
  }
}

@mixin mq-pc {
  @media screen and (min-width: $pcSize) {
    @content;
  }
}
