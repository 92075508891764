@charset "UTF-8";
@import "core/brackpoint";
@include mq-pc{
}
@include mq-sp{
}


//2024.02.22 add　課題258
.s-p_wrap {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: repeat(2, auto);
  gap: 100px;
  width: 1100px;
  @include mq-sp {
    display: block;
    width: 100%;
  };

  .contents {
    width: calc((1100px - 100px) / 2);
    margin: 0 !important;
    @include mq-sp{
      width: 100%;
      & + .contents {
        padding-top: 25px;
      }
    };
  }
  .slider_area {
    width: 400px;
    margin: 0 auto;
    @include mq-sp{
      width: 70%;
    };
    img {
      width: 100%;
    }
  }
  .people .left {
    width: 75%;
    @include mq-sp{
      width: 65%;
    };
  }
}

.garden .S_03_2024 {
  bottom: 0;
  right: 56px;
}



//2024.03.13 add　課題261
@include mq-sp {
  .facility {
    .facility-box {
      display: flex;
      flex-direction: column-reverse;
      gap: 10px;
      padding: 10px 20px;
      dl {
        width: 100%;
        dt {
          text-align: center;
        }
      }
      p {
        width: 100%;
        margin: 0;
        img {
          width: 100%;
          height: auto
        }
      }
    }
  }
};

#insta-block {
  padding-bottom: 80px;
  h2 {
    max-width: 240px;
    width: 100%;
    margin: 0 auto 30px auto;
    @include mq-sp {
      max-width: 150px;
    };
  }
  p {
    text-align: center;
  }
  a {
    display: block;
    max-width: 580px;
    margin: 30px auto 0 auto;
    padding: 10px;
    background-color: #fff;
    @include mq-sp {
      max-width: 90%;
    };
  }
}

@include mq-pc {
  #wedding .contents_area.left {
    margin-bottom: 160px;
  }

  .restaurant .contents_area.left {
    padding-bottom: 480px;
  }
};

.chapel .S_02 {
  bottom: -56px !important;
}

.chapel .S_03 {
  bottom: -56px !important;
}



// 2024.06.12 add 課題274
.modal_box .left .price ul li {
  width: 480px;
}
.modal_box .left {
  width: 470px;
}